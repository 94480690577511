import darkThemeFactory from './mode/dark'
import lightThemeFactory from './mode/light'

export default lightThemeFactory('#b8acff', '#3450ff', '#002bd0', {
  primary: 'Open Sans',
  secondary: 'Inter',
})
export const defaultMode = 'light'
export const alternativeMode = darkThemeFactory(
  '#b8acff',
  '#3450ff',
  '#002bd0',
  {
    primary: 'Open Sans',
    secondary: 'Inter',
  }
)
