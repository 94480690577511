import { ERC1155ABI, ERC20ABI, RARUMSALESABI } from 'core/constants'
import { RootState } from 'core/modules/redux'
import memoizeOne from 'memoize-one'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGalleries } from '../gallery/gallery.hook'
import { useTenant } from '../tenant/tenant.hook'
import { actions as tokenActions } from './token.reducer'
import * as TokenTypes from './token.types'
import { OnChainOfferType, OnChainOfferCollectionType } from './token.types'
import { useUser } from '../user'
import { normalizePrice } from 'core/helpers/contract'
import { useMarketplace } from 'context/Marketplace'
import { AssetType, AssetWithBalance, Sale } from '../asset/asset.types'
import useWeb3Provider, { useWeb3Providers } from 'hooks/useWeb3Provider'
import { GalleryType } from '../gallery/gallery.types'
import { useRouteGallery } from 'openspace/hooks/useRoutePathGallery'
import { DeprecatedTenantType } from '../tenant/tenant.types'
import { useStable } from 'context/Chain'

const getNftBalances = (
  wallet: string,
  nftContract: Contract.ERC1155,
  callback?: (balances: TokenTypes.TokenNftBalance) => void
) => {
  if (wallet && nftContract) {
    return nftContract.methods
      .balancesOf(wallet)
      .call()
      .then((result) => {
        const { balances, tokens } = result as TokenTypes.TokenNftBalance
        if (callback) callback({ tokens, balances })
        return { tokens, balances }
      })
      .catch((e) => {
        console.error(
          `Failed to request balances from contract ${nftContract.options.address}`,
          e
        )
        return Promise.reject(e)
      })
  }
}

export const useBalance = (wallets: string[]) => {
  const { tenant } = useTenant()
  const { galleries } = useGalleries()
  const routeGallery = useRouteGallery()
  const allAddresses = useMemo(() => {
    if (!tenant || !galleries) return []
    const nftAddressList =
      galleries
        .filter((a) => (routeGallery ? a.id === routeGallery.id : true))
        .map(
          (gallery) => gallery.smartContract?.address! || gallery.nftAddress
        ) || []
    if ((tenant as DeprecatedTenantType).smartContract?.address)
      nftAddressList.push(
        (tenant as DeprecatedTenantType).smartContract!.address!
      )
    return Array.from(new Set(nftAddressList))
  }, [tenant, galleries, routeGallery])

  return useBalanceForContracts(wallets, allAddresses)
}

const useBalanceForContracts = (
  _wallets: string[],
  contractAddresses: string[]
) => {
  const Contract = useWeb3Provider(true)?.eth.Contract
  const { tenant } = useTenant()
  const dispatch = useDispatch()
  const [nftBalances, setNftBalances] = useState<{
    [address: string]: {
      [wallet: string]: TokenTypes.TokenNftBalance
    }
  }>({})

  const wallets = useMemo(() => _wallets.filter(Boolean), [_wallets])

  useEffect(() => {
    if (!Contract) return
    for (let wallet of wallets) {
      for (let address of contractAddresses) {
        getNftBalances(
          wallet,
          new Contract(ERC1155ABI, address) as any,
          ({ tokens, balances }) => {
            setNftBalances((n) => ({
              ...n,
              [address]: {
                ...(n[address] || {}),
                [wallet]: { tokens, balances },
              },
            }))
          }
        )
      }
    }
  }, [dispatch, wallets, tenant, contractAddresses, Contract])

  useEffect(() => {
    if (!Contract) return
    const stableAddress = tenant?.stable?.address
    if (stableAddress) {
      const stableContract = new Contract(ERC20ABI, stableAddress)
      for (let wallet of wallets) {
        stableContract.methods
          .balanceOf(wallet)
          .call()
          .then((balance: string) => {
            dispatch(
              tokenActions.balanceOfErc20({
                token: stableAddress || '',
                balance: balance,
              })
            )
          })
          .catch(console.error)
      }
    }
  }, [dispatch, tenant, wallets, Contract])

  const loadedAllInformation = useMemo(() => {
    if (wallets.length === 0) return false
    const allLoadedAddresses = Object.keys(nftBalances)
    const hasInformationForAllAddresses =
      allLoadedAddresses.length === contractAddresses.length
    if (!hasInformationForAllAddresses) return false
    else {
      const allAddressHaveAllWalletsInformation = !Object.values(
        nftBalances
      ).some((walletsMap) => Object.keys(walletsMap).length !== wallets.length)

      return allAddressHaveAllWalletsInformation
    }
  }, [wallets, contractAddresses, nftBalances])

  useEffect(() => {
    if (!loadedAllInformation) return
    const consolidatedBalance: TokenTypes.TokenNftBalance | undefined =
      Object.values(nftBalances).reduce(
        (_result, nftWallets) =>
          Object.values(nftWallets).reduce(
            (result, nft) => ({
              tokens: nft?.tokens?.length
                ? result?.tokens?.concat(nft?.tokens)
                : result?.tokens,
              balances: nft?.balances?.length
                ? result?.balances?.concat(nft?.balances)
                : result?.balances,
            }),
            _result as any
          ),
        { tokens: [], balances: [] }
      )
    consolidatedBalance &&
      dispatch(tokenActions.balancesOfNft(consolidatedBalance))
  }, [dispatch, nftBalances, loadedAllInformation])

  const isLoading =
    useSelector<RootState, boolean>(({ token }) => token.isLoading) ||
    loadedAllInformation === false
  const nftBalancesStore = useSelector<
    RootState,
    TokenTypes.TokenNftBalance | null
  >(({ token }) => token.nftBalances)
  const erc20BalanceStore = useSelector<
    RootState,
    TokenTypes.TokenErc20Balance | null
  >(({ token }) => token.erc20Balance)

  return {
    isLoading,
    nftBalances: nftBalancesStore,
    erc20Balance: erc20BalanceStore,
  }
}

const memoizeTokens = memoizeOne((...ids: string[]) => ids)
const memoizeBalance = memoizeOne((...ids: string[]) => ids)

const filterTokensAndBalances = ({
  allBalances,
  allTokens,
}: {
  allBalances: string[]
  allTokens: string[]
}) => {
  const filteredTokens: AssetType['tokenId'][] = []
  const filteredBalance: number[] = []
  allBalances.forEach((bal, idx) => {
    const nBalance = Number(bal)
    if (nBalance > 0) {
      const nToken = Number(allTokens[idx])
      filteredTokens.push(nToken)
      filteredBalance.push(nBalance)
    }
  })
  return [filteredTokens, filteredBalance] as const
}

export const useMyTokens = () => {
  const { profile } = useUser()

  const wallets = useMemo(
    () =>
      [profile?.wallet, profile?.internalWallet].filter(Boolean) as string[],
    [profile]
  )

  const { nftBalances: state, isLoading } = useBalance(wallets)

  // Memoize these values so they don't retrigger the expensive filtering below
  const t = state?.tokens || []
  const allTokens = memoizeTokens(...t)
  const b = state?.balances || []
  const allBalances = memoizeBalance(...b)

  // Discards assets whose balance is zero
  return useMemo(
    () =>
      [
        ...filterTokensAndBalances({ allTokens, allBalances }),
        isLoading,
      ] as const,
    [allTokens, allBalances, isLoading]
  )
}

export const useBalanceByToken = (token?: AssetType['tokenId']) => {
  const [tokens, balances] = useMyTokens()

  const idx = tokens.findIndex((t) => String(t) === String(token))

  if (idx >= 0) {
    return {
      balance: Number(balances[idx]),
    }
  }
  return {
    balance: undefined,
  }
}

export const useBatchBalances = (
  accounts: Array<string>,
  ids: Array<string>,
  nftAddress: string | undefined
) => {
  const Contract = useWeb3Provider(true)?.eth.Contract
  const [batchBalances, setBatchBalances] =
    useState<TokenTypes.NftBatchBalances>({})

  useEffect(() => {
    if (!Contract) return
    if (accounts.length && ids.length && nftAddress) {
      const contract = new Contract(ERC1155ABI, nftAddress)
      const balances: TokenTypes.NftBatchBalances = {}

      contract.methods
        .balanceOfBatch(accounts, ids)
        .call()
        .then((result: Array<string>) =>
          result.forEach((balance: string, index: number) => {
            if (!(accounts?.[index] in balances)) {
              balances[accounts?.[index]] = {
                balances: [],
                tokens: [],
              }
            }
            balances[accounts?.[index]].balances?.push(balance)
            balances[accounts?.[index]].tokens?.push(ids[index])
          })
        )
      setBatchBalances(balances)
    }
  }, [accounts, nftAddress, Contract])

  return { batchBalances }
}

export const useOnChainOffers = (
  salesAddress?: string,
  nftAddress?: string
) => {
  const { readWeb3, writeWeb3 } = useWeb3Providers()
  const Contract = readWeb3?.eth.Contract
  const [loading, setLoading] = useState<boolean>(true)
  const [offers, setOffers] = useState<OnChainOfferType[] | null>(null)
  const [collection, setCollection] =
    useState<OnChainOfferCollectionType | null>(null)
  const [fee, setFee] = useState(0)
  const [contractAddress, setContractAddress] = useState<string>('')

  const refreshOffers = useCallback(
    function () {
      if (salesAddress && nftAddress && Contract) {
        const rarumSalesAddress = salesAddress
        setContractAddress(rarumSalesAddress)
        const salesContract = new Contract(RARUMSALESABI, rarumSalesAddress)
        const now = Date.now() / 1000
        salesContract.methods
          .offersInCollection(nftAddress)
          .call()
          .then((result: OnChainOfferType[]) => {
            return setOffers(
              result
                .filter((a) => now < a.endTime)
                .filter((a) => a.active)
                .filter((a) => !a.fromTenant)
                .map((offer) => ({
                  ...offer,
                  deliveryOptions: {
                    ...offer.deliveryOptions,
                    tokenId: Number(offer.deliveryOptions.tokenId),
                  },
                  paymentOptions: {
                    ...offer.paymentOptions,
                    price: Number(offer.paymentOptions.price),
                  },
                }))
                .sort((a, b) => a.paymentOptions.price - b.paymentOptions.price)
            )
          })
          .catch((error: any) => console.error(error))

        salesContract.methods
          .authorizedCollections(nftAddress)
          .call()
          .then((result: OnChainOfferCollectionType) => {
            setFee(Number(result.fee) / 100)
            return setCollection(result)
          })
          .catch((error: any) => console.error(error))

        setLoading(false)
      }
      if (!salesAddress) {
        setOffers([])
        setLoading(false)
      }
    },
    [salesAddress, nftAddress, Contract]
  )

  useEffect(() => {
    refreshOffers()
  }, [refreshOffers])

  return {
    loading,
    offers,
    collection,
    fee,
    buy: (owner: string, tokenId: number, amount: number) => {
      if (!writeWeb3) return
      const salesContract = new writeWeb3.eth.Contract(
        RARUMSALESABI,
        contractAddress
      )

      return salesContract.methods.buy(owner, tokenId, amount).send()
    },
    refreshOffers,
  }
}

export const useStableBalance = (wallet?: string) => {
  const stables = useStable()
  const Contract = useWeb3Provider(true)?.eth.Contract
  const [stableBalance, setStableBalance] = useState<{
    [stable: string]: number
  }>({})
  const updateBalance = useCallback(
    function updateBalance() {
      if (!wallet) return
      stables.forEach((stable) => {
        if (stable.address && Contract) {
          const stableContract = new Contract(ERC20ABI, stable.address)
          stableContract.methods
            .balanceOf(wallet)
            .call()
            .then((result: number) =>
              setStableBalance((prev) => ({
                ...prev,
                [stable.symbol]: normalizePrice(result, stable.decimals),
              }))
            )
        }
      })
    },
    [wallet, Contract, stables]
  )

  return {
    stableBalance,
    updateBalance,
  }
}

/**
 * Returns the amount of items available for sale
 */
export const useRemainingAvailableTokens = (
  asset?: AssetWithBalance | null,
  offerId?: Sale['offerId']
): { availableForSale?: number; latestOfferId?: string } => {
  const { sales } = useMarketplace()
  const { profile } = useUser()
  return useMemo(() => {
    if (!asset || !sales.items) return {}

    const myAssetSales = sales.items?.filter(
      (i) =>
        i.wallet === profile?.wallet &&
        i.offerId !== offerId &&
        i.assetId === asset.id
    )

    const tokenSales = myAssetSales?.reduce((r, i) => r + 1, 0) || 0

    const balanceForToken = asset.balance
    const ret = {
      availableForSale:
        typeof balanceForToken === 'number'
          ? balanceForToken - tokenSales
          : undefined,
      latestOfferId:
        myAssetSales && myAssetSales.length > 0
          ? myAssetSales[myAssetSales.length - 1].offerId
          : undefined,
    }

    return ret
  }, [asset, sales.items, profile?.wallet, offerId])
}

export function useBalancesForGalleries(
  wallets: string[],
  galleries: GalleryType[]
) {
  const contracts = useMemo(() => {
    return galleries.map((g) => g.smartContract!.address)
  }, [galleries])
  return useBalanceForContracts(wallets, contracts)
}
